/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React, { useRef, useState } from 'react'
import { get, isFunction } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from '@emotion/react'

import Section from '../section'
import Button from '../button'
import ImageFill, { RatioBox } from '../image'

import {
  Cont,
  TextBlock,
  FlexCols,
  FlexCol,
  Space,
  VStack,
} from '../../styles/els'
import { fillArea } from '../../styles/css'

import useLoop from '../../lib/hooks/use-loop'

import { formatImage, renderHTML, resolveButtons } from '../../lib/helpers'
import CenterCol from '../center-col'

const query = graphql`
  query {
    productions: allWpProduction(
      sort: {
        order: [DESC, ASC]
        fields: [acfProduction___dateStart, menuOrder]
      }
      filter: { acfProduction: { isCurrent: { ne: true } } }
      limit: 15
    ) {
      nodes {
        acfProduction {
          image {
            ...InlineImage
          }
        }
      }
    }
  }
`

const nextTickInc = () => {
  return 1 + Math.floor(Math.random() * 4)
}

const PastProductionsCta = (props) => {
  const data = useStaticQuery(query)
  const productions = get(data, 'productions.nodes')

  const [tick, setTick] = useState(0)
  const nextTick = useRef(nextTickInc())

  useLoop(
    ({ frame }) => {
      if (frame > nextTick.current) {
        nextTick.current = frame + nextTickInc()
        setTick(tick + 1)
      }
    },
    {
      fps: 2,
    },
    [tick]
  )

  const productionIndex = tick % productions.length
  const image = formatImage(
    get(productions, `${productionIndex}.acfProduction.image`)
  )

  return (
    <Section
      inset={true}
      bg="grey4"
      bgEl={
        <>
          <ImageFill image={image} />
          <div
            sx={{
              backgroundColor: 'grey4',
              opacity: 0.7,
            }}
            css={css`
              ${fillArea}
            `}
          />
        </>
      }
    >
      <CenterCol sx={{ py: 7 }}>
        <VStack>
          <h4 sx={{ variant: 'text.heading_36', color: 'white' }}>
            Past productions
          </h4>
          <div sx={{ variant: 'textBlock.25' }}>
            Browse our archive of productions over{' '}
            <span sx={{ color: 'red' }}>20+ years</span>
          </div>
          <div sx={{ pt: 4 }}>
            <Button
              to="/whats-on/past-productions/"
              color="red"
              label="Browse archive"
            />
          </div>
        </VStack>
      </CenterCol>
    </Section>
  )
}

export default PastProductionsCta
