import React, { useRef, useEffect } from 'react'
import requestFrame from 'request-frame'
import { isFinite, isFunction } from 'lodash'

export default (cb, settings = {}, deps = []) => {
  const { fps } = {
    fps: 60,
    ...settings,
  }
  const loopRef = useRef(null)
  const prevTimeRef = useRef(null)
  const startTimeRef = useRef(null)
  const frame = useRef(0)

  const nextStep = () => {
    const request = requestFrame('request')
    loopRef.current = request(animateStep)
  }

  const cancelStep = () => {
    if (loopRef.current) {
      const cancel = requestFrame('cancel')
      cancel(loopRef.current)
    }
  }

  const animateStep = (t) => {
    const timeElapsed = t - prevTimeRef.current

    if (!isFinite(startTimeRef.current)) {
      startTimeRef.current = t
    }

    if (
      !isFinite(prevTimeRef.current) ||
      !isFinite(timeElapsed) ||
      timeElapsed >= 1000 / fps
    ) {
      cb({
        delta: timeElapsed,
        elapsed: t - startTimeRef.current,
        frame: frame.current,
      })

      prevTimeRef.current = t
      frame.current = frame.current + 1
    }

    nextStep()
  }

  useEffect(() => {
    if (isFunction(cb)) {
      nextStep()
    }

    return () => {
      cancelStep()
    }
  }, [cb, fps, ...deps])

  return null
}
